<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model.number="fristInMin"
            outlined
            :rules="[rules.required, rules.min]"
            @input="$emit('update:fristInMin', $event)"
            type="number"
            :label="fieldLabel"
            hint="Die Rückmeldefrist wird individuell berechnet. Beispiel: Bei Eingabe von 60 Minuten ist die Abgabe von Rückmeldungen bis 60 Minuten vor Beginnzeitpunkt möglich."
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-btn
            depressed
            @click="minus(60)"
            :disabled="fristInMin < 60"
            class="mt-3 mx-1"
          >
            <v-icon>mdi-rewind-60</v-icon>
          </v-btn>
          <v-btn
            depressed
            @click="minus(30)"
            :disabled="fristInMin < 30"
            class="mt-3 mx-1"
          >
            <v-icon>mdi-rewind-30</v-icon>
          </v-btn>
          <v-btn depressed @click="plus(30)" class="mt-3 mx-1">
            <v-icon>mdi-fast-forward-30</v-icon>
          </v-btn>
          <v-btn depressed @click="plus(60)" class="mt-3 mx-1">
            <v-icon>mdi-fast-forward-60</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "event-deadline-config",
  props: {
    fristInMin: {
      type: Number,
      required: true,
    },
    fieldLabel: {
      type: String,
      required: false,
      default: "Globale Rückmeldefrist in Minuten",
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        min: (value) =>
          /^[+]?\d+([.]\d+)?$/.test(value) || "Muss positive Zahl sein.",
      },
    };
  },
  methods: {
    minus(value) {
      this.fristInMin = this.fristInMin - value;
      this.$emit("update:fristInMin", this.fristInMin);
    },
    plus(value) {
      this.fristInMin = this.fristInMin + value;
      this.$emit("update:fristInMin", this.fristInMin);
    },
  },
};
</script>

<style></style>
