<template>
  <div>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Veranstaltungen basieren auf einer Veranstaltungsart. Jeder Veranstaltungsart sind verschiedene Funktionen und Parameter zugewiesen, die sich teilweise auch auf bestehende Veranstaltungen auswirken können. Auf abgeschlossene Veranstaltungen haben Änderungen keine Auswirkung."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.dataImport.snackbar.model" timeout="5000"
      >{{ helpers.dataImport.snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-format-list-bulleted-type"
                title="Veranstaltungsarten"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="eventTypes"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Veranstaltungsarten vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row class="match-height">
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card
                          v-bind:style="createBorderColorStyle(item.color)"
                          @click="routeDetails(item.meta.id)"
                        >
                          <v-card-title class="subheading">
                            {{ item.title }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.description }}
                          </v-card-subtitle>
                          <v-card-text>
                            <span>
                              <v-icon small>mdi-sort-numeric-ascending</v-icon>
                              {{ item.sortKey }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Veranstaltungsarten importieren"
      text="Importierte Veranstaltungsarten stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImport.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Veranstaltungsarten suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                      <!-- <v-col
                        ><v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left small>mdi-sort-variant</v-icon>
                              Sortieren nach

                              {{ eventsFilter }}
                              <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                          </template>
                          <v-list nav
                            v-model="eventsFilter">
                            <v-list-item-group mandatory>
                              <v-list-item link dense value="Alle">
                                <v-list-item-icon>
                                  <v-icon dense
                                    >mdi-sort-numeric-variant</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Sortierschlüssel</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item link dense value="Eigene">
                                <v-list-item-icon>
                                  <v-icon dense
                                    >mdi-sort-alphabetical-variant</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Alphabetisch</v-list-item-title
                                >
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu></v-col
                      >-->
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <small class="grey--text">{{ item.description }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { SCHEDULING } from "@/store/modules.js";
import { GET_EVENT_TYPES, CREATE_EVENT_TYPES } from "@/store/action-types.js";
import { SCHEDULING_CONFIG_EVENT_TYPES_create } from "@/data/permission-types.js";
import { importableEventTypes } from "@/data/import/importable-eventTypes.js";

export default {
  name: "scheduling-config-tab-event-types",
  components: {
    TippCard,
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "sortKey",

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "scheduling-config-tab-event-types-event-type-new",
            });
          },
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
          actionStyle: "textBtn",
          function: this.toggleImportDialog,
        },
      ],

      helpers: {
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableEventTypes,
          sortSelector: "sortKey",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
          snackbar: { model: false, text: "" },
        },
      },
    };
  },
  computed: {
    eventTypes() {
      return this.$store.state.scheduling.eventTypes;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      return this.$store.dispatch(`${SCHEDULING}${GET_EVENT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        this.$store
          .dispatch(`${SCHEDULING}${CREATE_EVENT_TYPES}`, {
            organizationId: this.$route.params.organizationId,

            eventTypes: this.helpers.dataImport.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.dataImport.snackbar.model = true;
            this.helpers.dataImport.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Veranstaltungsart aus, um den Import duchrzuführen."
        );
      }
    },
    routeDetails(item) {
      this.$router.push({
        name: "scheduling-config-tab-event-types-event-type-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
  },
};
</script>
<style>
.transparent-background {
  background-color: transparent !important;
}
</style>
