<template>
  <div>
    <NavigationBar
      titel="Veranstaltungsart bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <event-type-editor v-model="eventType" mode="edit"></event-type-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { UPDATE_EVENT_TYPE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import EventTypeEditor from "@/components/scheduling/EventTypeEditor.vue";

export default {
  name: "scheduling-config-tab-event-type-edit",
  components: {
    NavigationBar,
    EventTypeEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      eventType: null,

      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("eventTypes")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.eventType = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    updateItem() {
      this.validate();

      if (this.helpers.formIsValid) {
        var res = confirm(
          "Bist Du sicher, dass Du die Veranstaltungsart aktualisieren möchtest und alle bevorstehenden Veranstaltungen angepasst werden sollen?"
        );
        if (res == true) {
          this.$store.dispatch(`${SCHEDULING}${UPDATE_EVENT_TYPE}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            title: this.eventType.title,
            shortTitle: this.eventType.shortTitle,
            description: this.eventType.description,
            color: this.eventType.color,
            sortKey: this.eventType.sortKey,
            dutyType: {
              title: this.eventType.dutyType.title,
              id: this.eventType.dutyType.id,
            },
          });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
