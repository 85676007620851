<template>
  <div>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Um das Erstellen von Veranstaltungen zu erleichtern, kannst Du diese aus Vorlagen heraus erstellen. Lege dafür die gewünschten Werte im Vorfeld fest und speichere diese als Vorlage ab. Beim Anlegen einer Veranstaltung kannst Du nun die bereits eingegeben Daten schnell und unkompliziert übernehmen."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-book"
                title="Vorlagen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="templates"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Vorlagen vorhanden"
                >
                  <!-- <template v-slot:header>
                    <v-toolbar outlined flat class="mb-4">
                      <v-text-field
                        v-model="search"
                        clearable
                        flat
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Suche"
                        style="max-width:380px"
                      ></v-text-field>
                      <template>
                        <v-spacer></v-spacer>
                        <v-btn-toggle dense v-model="sortDesc" mandatory>
                          <v-btn outlined :value="true">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn outlined :value="false">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </template>
                    </v-toolbar>
                  </template> -->

                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card @click="routeDetails(item.meta.id)">
                          <v-card-title class="subheading">
                            {{ item.template.title }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.template.description }}
                          </v-card-subtitle>
                          <v-card-text>
                            <span>
                              <v-icon small>mdi-sort-numeric-ascending</v-icon>
                              {{ item.template.sortKey }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { SCHEDULING } from "@/store/modules.js";
import { GET_EVENT_TEMPLATES } from "@/store/action-types.js";
import { SCHEDULING_CONFIG_EVENT_TEMPLATES_create } from "@/data/permission-types.js";

export default {
  name: "scheduling-config-tab-templates",
  components: {
    TippCard,
    Subheader,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "template.sortKey",

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_create}`,
          actionStyle: "textBtn",
          function: this.vorlageErstellen,
        },
      ],
    };
  },
  computed: {
    templates() {
      return this.$store.state.scheduling.eventTemplates;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${SCHEDULING}${GET_EVENT_TEMPLATES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    vorlageErstellen() {
      this.$router.push({
        name: "scheduling-config-tab-templates-template-new",
      });
    },
    routeDetails(item) {
      this.$router.push({
        name: "scheduling-config-tab-templates-template-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
  },
};
</script>
