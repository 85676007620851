<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <v-card v-bind:style="createBorderColorStyle(eventType.color)">
                  <v-card-title class="subheading">
                    {{ eventType.title }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ eventType.description }}
                  </v-card-subtitle>
                  <v-card-text>
                    <span>
                      <v-icon small>mdi-sort-numeric-ascending</v-icon>
                      {{ eventType.sortKey }}
                    </span>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
// import { ADMIN } from "@/store/modules.js";
// import { DELETE_FUNKTION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import {
  SCHEDULING_CONFIG_EVENT_TYPES_create,
  SCHEDULING_CONFIG_EVENT_TYPES_delete,
} from "@/data/permission-types.js";

export default {
  name: "scheduling-config-tab-event-type-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permission: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      sortDesc: true,
      sortBy: "title",

      eventType: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [{ title: "eventType", data: this.eventType }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("eventTypes")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.eventType = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
    editItem() {
      this.$router.push({
        name: "scheduling-config-tab-event-types-event-type-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      alert(
        "Fehler: Das Löschen von Veranstaltungsarten ist aktuell nicht möglich. Bitte kontaktiere den Support."
      );
    },
  },
};
</script>

<style></style>
